<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

/**
 * Form-element component
 */
export default {
  page: {
    title: "Forms Elements",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Profile",
      img: null,
      base64: null,
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Profile",
          active: true
        }
      ],
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true
    };
  },

  mounted(){
    this.$store.dispatch('users/getUsers')
  },

  computed: {
    user() {
      const id= JSON.parse(localStorage.getItem('user')).user._id
      return this.$store.getters['users/getUser'](id)
    }
  },

  methods: {
    getImgValue() {
      const img = this.$refs.img.files[0]
      if (img) {
        const reader = new FileReader();

        reader.onload = () => {
          this.base64 = reader.result
        }
        reader.readAsDataURL(img);
      }
     
    },

    async updatePhoto(){
      
      await axios.post('upload-photo/'+ this.user._id,{base64_image: this.base64},{
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
        }
      }).then((res) =>{
        console.log('uploaded',res)
        this.base64=null
        this.$bvToast.toast('Profile Picture Updated', {
          variant: 'success',
          solid: true
        })
      }).catch((err)=>{
        console.log('upload error',err)
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Profile Info</h4>
            <div>
              <div class="row">
                <img alt="200x200" v-if="!user.photo_url" src='../../../assets/images/users/dummy-avatar.jpg' data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl mt-2">
                <img alt="200x200" v-if="user.photo_url" :src='user.photo_url' data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl mt-2">

                
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <input type="file" id="img" accept="image/*" ref="img" @change="getImgValue" class="ml-3" />
                </div>
                <div class="col-12 mt-2 ml-3">
                  <b-button v-if="base64" variant="primary" @click="updatePhoto()">Save</b-button>
                </div>
                
                
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-12">

                <form class="form-horizontal" role="form">
                  <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="First Name" label-for="text">
                    <b-form-input for="text" :value="user.firstName" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Last Name" label-for="text">
                    <b-form-input for="text" :value="user.lastName" disabled></b-form-input>
                  </b-form-group>



                  <b-form-group id="example-email" label-cols-sm="2" label-cols-lg="2" label="Email" label-for="email">
                    <b-form-input id="email" :value="user.email" disabled></b-form-input>
                  </b-form-group>



                  <b-form-group id="example-tel" label-cols-sm="2" label-cols-lg="2" label="Phone Number"
                    label-for="tele">
                    <b-form-input id="tele" :value="user.phoneNumber" type="tel" name="tel" disabled></b-form-input>
                  </b-form-group>




                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->



    <!-- end row -->
  </Layout>
</template>